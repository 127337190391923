import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレーでセキュリティエンジニアとして働くということ",
  "date": "2021-05-28T08:50:04.000Z",
  "slug": "entry/2021/05/28/175004",
  "tags": ["medley"],
  "hero": "./2021_05_28.png",
  "heroAlt": "セキュリティエンジニア"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして。メドレーでセキュリティエンジニアをしている三浦です。`}</p>
    <p>{`私はメドレーには今年（2021 年）の 2 月に入社し、このブログを執筆している時点で入社 4 ヶ月目となります。現在、全社的なセキュリティを担当しています。`}</p>
    <p>{`今回のブログは以下のような構成でお届けします。`}</p>
    <h1>{`目次`}</h1>
    <ol>
      <li parentName="ol">{`自己紹介と、本ブログのテーマについて`}</li>
      <li parentName="ol">{`前職でのセキュリティエンジニアとしての仕事`}</li>
      <li parentName="ol">{`メドレーでのセキュリティエンジニアの仕事`}</li>
      <li parentName="ol">{`セキュリティエンジニアとしての活動内容や役割の違いと気づき、課題`}</li>
      <li parentName="ol">{`セキュリティエンジニア募集中！`}</li>
    </ol>
    <h1>{`自己紹介と、本ブログのテーマについて`}</h1>
    <p>{`私は前職でセキュリティ専業会社のセキュリティエンジニアとして脆弱性診断やペネトレーションテストのほか、セキュリティ研修の講師、ファイアウォールや Web アプリケーションファイアウォール（WAF）と呼ばれる機器の導入支援などに携わっていました。`}</p>
    <p>{`このなかでも脆弱性診断の案件に多く関わっていたこともあり、最後には脆弱性診断のサービスオーナーとしてサービスに関わるすべてを取りまとめる立場でした。`}</p>
    <p>{`今では事業会社のセキュリティエンジニアとして活動しているわけですが、このブログではセキュリティエンジニアとしての活動内容や役割にどのような変化があり、その変化により直面している自分自身の課題や苦労について書いていきます。`}</p>
    <h1>{`前職でのセキュリティエンジニアとしての仕事`}</h1>
    <p>{`先述のとおり前職では脆弱性診断に関わることが多かったため、ここでは脆弱性診断について取り上げます。`}</p>
    <p>{`脆弱性診断はスポット案件として実施することがほとんどでしたので、案件の流れという観点で仕事内容を整理すると以下のようになります。`}</p>
    <ol>
      <li parentName="ol">{`営業フォロー（同行や Q&A など）`}</li>
      <li parentName="ol">{`スケジュールや診断対象などについてお客様にヒアリング
a. ここで診断用環境の準備をお願いすることが多い`}</li>
      <li parentName="ol">{`診断対象の調査、クロール作業`}</li>
      <li parentName="ol">{`スケジュール感や制限事項についてお客様とすり合わせ`}</li>
      <li parentName="ol">{`計画書を作成`}</li>
      <li parentName="ol">{`社内で計画書レビュー、お客様へ送付`}</li>
      <li parentName="ol">{`診断作業
a. 自動診断ツールの調整
a. 手動診断の実施
a. 出力結果の精査、再現性の確認
a. 速報があれば当日中に整理して送付`}</li>
      <li parentName="ol">{`診断結果のエビデンス整理`}</li>
      <li parentName="ol">{`報告書の下書き、リスク度合いの検討`}</li>
      <li parentName="ol">{`社内で報告書レビュー`}</li>
      <li parentName="ol">{`お客様へ報告書を納品`}</li>
      <li parentName="ol">{`報告会の実施`}</li>
    </ol>
    <p>{`このなかで、特に診断担当者の技術的スキルが問われたのが「手動診断の実施」と「出力結果の精査、再現性の確認」で、全体のうち約 4 割ほど。残りはドキュメント作成、レビュー、ミーティング、業務改善といった定型業務が占めていました。`}</p>
    <p>{`私は「この 4 割の部分に対して付加価値を提供したい」というエンジニアとしての気負いと、個人的な関心という 2 つの理由から、寝る時間を削っては脆弱性の悪用（Exploit）手法の習得と、知識的な補強としての資格取得に明け暮れる時期がありました。`}</p>
    <p>{`結局、寝不足が続いたことが原因で肺炎を発症し、入院一歩前まで炎症マーカーが悪化するという自分自身の脆弱性が露呈することになったのですが。ちゃんと夜は寝ましょう。`}</p>
    <p>{`話がすこし脱線しましたが、脆弱性診断や SI 案件などは年度末にピークを迎えるものの年間を通じて五月雨で受注することも多く、一定の品質を維持しながらも納期を守るために目の前の案件で必死だったというのが今までの仕事のスタイルでした。`}</p>
    <p>{`目の前の案件をひたすらこなし、組織に対して「報告書を提出して終わり」の関わり方に対して『これで社会の役に立っているのだろうか』という疑問が生まれたことが、メドレーに入社する転機になっています。`}</p>
    <h1>{`メドレーでのセキュリティエンジニアの仕事`}</h1>
    <p>{`さて、セキュリティサービスを提供する側で案件に追われていた私がメドレーでセキュリティエンジニアとして活動することになっているわけですが、具体的に今なにをしているのか？というと、大きくは以下の 6 つが挙げられます。`}</p>
    <ul>
      <li parentName="ul">{`自社サービスに対する脆弱性診断`}</li>
      <li parentName="ul">{`脅威情報や脆弱性情報の収集、周知`}</li>
      <li parentName="ul">{`全社的な業務プロセス上のリスク調査`}</li>
      <li parentName="ul">{`全社システムの BCP 整備、保守`}</li>
      <li parentName="ul">{`ISMS 運用`}</li>
      <li parentName="ul">{`セキュリティ相談`}</li>
    </ul>
    <p>{`自社サービスに対する脆弱性診断というのは「脆弱性診断の内製化」で、脆弱性診断を外部の業者にお願いするのではなく自社内で実施して開発にフィードバックする形です。`}</p>
    <p>{`内製による診断であっても、基本的な流れはすでにご紹介した流れで進めていますが、対象サービスをクロールしてから診断対象を確定させるまでの主要なポイントでは、操作手順に漏れがないかなどの観点で開発からのレビューを挟むようにしています。これは内製であるからこそ実現できているアプローチだと思います。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "内製で実施している診断対象一覧の例"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210527/20210527151142.png",
        "alt": "20210527151142.png"
      }}></img><figcaption parentName="figure">{`内製で実施している診断対象一覧の例`}</figcaption></figure>
    <p>{`なお、ここまで脆弱性診断について書いてきましたが、これは現在の業務のほんの一部で、このほかには ISMS の運用（事務局）としての活動のほか、コーポレート IT メンバーと共に事業継続計画（BCP）整備なども並行して進めなければなりません。とにかく関わる範囲が広く、そして、とても地道な活動です。`}</p>
    <p>{`ですが、会社全体を俯瞰してセキュリティのことを意識して物事を考え続けられる立場というのはセキュリティ会社では経験できないことで、自社にとっての最適解は何か？を探求し続けることの新たな面白さを見いだすことができています。`}</p>
    <h1>{`セキュリティエンジニアとしての活動内容や役割の違いと気づき、課題`}</h1>
    <p>{`ここまでの内容でお気づきの方もいるかと思いますが、現在の業務は ISMS 運用を初めとしてリスクマネジメントの分野も多く、実務的なバックグラウンドのない業務も出てきています。`}</p>
    <p>{`システム的なセキュリティに関しても「どうやって悪用できるか...好物の BOF`}{`*`}{`は無いのか..BOF はどこだ...」という攻撃観点の思考回路のみで判断するのではなく、「どのようなソリューションや仕組み、またはルールでリスク低減できるのか、そして、それをなるべく自動で運用できるようにはどうするか」という守る側にとっての実務的な課題を考えなければなりません。`}</p>
    <p>{`*`}{` バッファオーバーフローのこと`}</p>
    <p>{`このほかに、私が実際に感じているセキュリティエンジニアとしての気づきや考え方の変化を挙げていくと、以下のようなものがあります。`}</p>
    <ul>
      <li parentName="ul">{`攻撃手法の理解を踏まえ、（社内リソースを鑑みた）現実的に運用しうる対策の仕組みを提案できなければならないことを痛感した`}</li>
      <li parentName="ul">{`仕組み化やルール化するにも、エンジニアではない従業員の存在を考慮するようになった`}</li>
      <li parentName="ul">{`脆弱性単体の影響範囲や度合いではなく、システムの利用方法や情報の種類など、社内だからこそ持てる視点を持って評価するようになった`}</li>
      <li parentName="ul">{`「アップデートしたくてもできない」問題が生じないシステム設計が肝要であることを改めて認識した`}</li>
    </ul>
    <p>{`これらの変化への対応については、すべてソリューションや自動化に対して私の知見がまだまだ不足しているため、これからは「攻撃者目線」の考え方に加えて「ソリューションと自動化」の知見を補完しながら、メドレーのセキュリティ向上に貢献し続けていきたいと考えています。`}</p>
    <h1>{`セキュリティエンジニア募集中！`}</h1>
    <p>{`最後となりますが、セキュリティエンジニアは関わる業務の幅広さに加え、絶対的な正解が存在しない問題にソリューションを提供することも多い仕事です。`}</p>
    <p>{`メドレーでの内製による脆弱性診断や全社セキュリティに興味がある方はぜひジョインしてください。いつでもお待ちしています！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      